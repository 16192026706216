import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardText,[_c('date-time-picker',{on:{"getDateFrom":function($event){_vm.dateFrom = $event},"getDateTo":function($event){_vm.dateTo = $event}}}),_c(VBtn,{staticClass:"mt-5",attrs:{"color":"primary"},on:{"click":_vm.getData}},[_vm._v(" "+_vm._s(_vm.$t('BtnSearch'))+" ")])],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"w-full"},[_c(VCardText,[_c('h2',[_vm._v(_vm._s(_vm.creativeName))])])],1)],1)],1),_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"w-full"},[_c(VCardText,[_c('campaign-creatives-chart',{attrs:{"data":_vm.dataFromDb}})],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataFromDb,"item-key":"creative_id","search":_vm.search,"loading":_vm.loading,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c(VTextField,{staticClass:"mx-4",attrs:{"label":_vm.$t('LblSearch')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.statisticsDate",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,[_vm._v(" "+_vm._s(_vm.moment(item.statisticsDate).format("YYYY-MM-DD"))+" ")])],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.$t('SelectedPeriod'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.totals.currentPeriod.views)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.totals.currentPeriod.clicks)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.totals.currentPeriod.ctr.toFixed(2))+" ")])]),_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.$t('AllPeriod'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.totals.totalPeriod.views)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.totals.totalPeriod.clicks)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.totals.totalPeriod.ctr.toFixed(2))+" ")])])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }