<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-time-picker
              @getDateFrom="dateFrom = $event"
              @getDateTo="dateTo = $event"
            >
            </date-time-picker>
            <v-btn
              class="mt-5"
              color="primary"
              @click="getData"
            >
              {{ $t('BtnSearch') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="w-full">
          <v-card-text>
            <h2>{{ creativeName }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="w-full">
          <v-card-text>
            <campaign-creatives-chart
              :data="dataFromDb"
            >
            </campaign-creatives-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dataFromDb"
          item-key="creative_id"
          class="elevation-1"
          :search="search"
          :loading="loading"
          :locale="$i18n.locale"
        >
          <template
            v-slot:top
          >
            <div class="row">
              <div class="col-12">
                <v-text-field
                  v-model="search"
                  :label="$t('LblSearch')"
                  class="mx-4"
                ></v-text-field>
              </div>
            </div>
          </template>
          <template #[`item.statisticsDate`]="{item}">
            <v-row>
              <v-col>
                {{ moment(item.statisticsDate).format("YYYY-MM-DD") }}
              </v-col>
            </v-row>
          </template>
          <template slot="body.append">
            <tr>
              <th
                colspan="1"
              >
                {{ $t('SelectedPeriod') }}
              </th>
              <th>
                {{ totals.currentPeriod.views }}
              </th>
              <th>
                {{ totals.currentPeriod.clicks }}
              </th>
              <th>
                {{ totals.currentPeriod.ctr.toFixed(2) }}
              </th>
            </tr>
            <tr>
              <th
                colspan="1"
              >
                {{ $t('AllPeriod') }}
              </th>
              <th>
                {{ totals.totalPeriod.views }}
              </th>
              <th>
                {{ totals.totalPeriod.clicks }}
              </th>
              <th>
                {{ totals.totalPeriod.ctr.toFixed(2) }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiVideoOutline, mdiChartLine } from '@mdi/js'
import qs from 'qs'
import DateTimePicker from '../Components/DateTimePicker.vue'
import CampaignCreativesChart from './Components/CampaignCreativesDailyChartComponent.vue'

export default {
  components: {
    DateTimePicker,
    CampaignCreativesChart,
  },
  data() {
    return {
      search: '',
      calories: '',

      isWeekly: false,
      isWeeklyTransfer: false,
      isVideoCampaign: false,
      brandSaftyData: [],
      excelStatistics: [],
      widgetsData: [],
      dataFromDb: [],
      creativeName: '',
      totals: {
        currentPeriod: {
          views: 0,
          ctr: 0.0,
          clicks: 0,
        },
        totalPeriod: {
          views: 0,
          ctr: 0.0,
          clicks: 0,
        },
      },
      headers: [],
      betType: 0,
      selectedCampaigns: [],
      creativeId: Number(this.$route.params.creative_id),
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      transferDateFrom: new Date().toISOString().substr(0, 10),
      transferDateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      icons: {
        mdiVideoOutline,
        mdiChartLine,
      },
      json_fields: {},
    }
  },

  watch: {
    selectedCampaigns() {
      // watch it
    },
  },

  mounted() {
    this.getData()

    // this.$root.$on('update_city', this.getData())
  },

  methods: {
    getData() {
      this.loading = true
      this.transferDateFrom = this.dateFrom
      this.transferDateTo = this.dateTo
      this.isWeeklyTransfer = this.isWeekly
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      let params = {}

      params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          userId: this.$getUserId(),
          creativeIds: this.creativeId,

          // advertiserId: this.advertiserId,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // '/creatives/daily/table-data'
      this.$http
        .get(`${this.$apiBaseURL}/creatives/details`, params)
        .then(response => {
          console.log(response.data)
          this.dataFromDb = response.data !== null ? response.data : []

          this.headers = []

          this.headers.push({ text: this.$t('Date'), value: 'statisticsDate' })
          this.headers.push({ text: this.$t('Shows'), value: 'views' })
          this.headers.push({ text: this.$t('References'), value: 'clicks' })
          this.headers.push({ text: this.$t('CTR'), value: 'ctr' })

          let count = 0
          let tempCTR = 0.0
          this.totals.currentPeriod.views = 0
          this.totals.currentPeriod.clicks = 0
          this.totals.currentPeriod.ctr = 0.0
          if (this.dataFromDb.length > 0) {
            this.dataFromDb.forEach(item => {
              this.totals.currentPeriod.views += item.views
              this.totals.currentPeriod.clicks += item.clicks
              tempCTR += item.ctr
              count += 1
            })
            if (tempCTR > 0.0) {
              this.totals.currentPeriod.ctr = tempCTR / count
            } else {
              this.totals.currentPeriod.ctr = 0.0
            }
          }

          this.loading = false
        })
        .then(() => {
          const paramsTotal = {
            params: {
              dateFrom: '1970-01-01',
              dateTo: `${new Date().toISOString().substr(0, 10)}`,
              userId: this.$getUserId(),
              creativeIds: this.creativeId,
            },
          }
          this.$http.get(`${this.$apiBaseURL}/creatives/details`, paramsTotal).then(responseTotal => {
            const tempData = responseTotal.data !== null ? responseTotal.data : []
            if (tempData.length > 0) {
              this.creativeName = tempData[0].creativeName
            }
            let count = 0
            let tempCTR = 0.0
            this.totals.totalPeriod.views = 0
            this.totals.totalPeriod.clicks = 0
            this.totals.totalPeriod.ctr = 0.0
            if (tempData.length > 0) {
              tempData.forEach(item => {
                this.totals.totalPeriod.views += item.views
                this.totals.totalPeriod.clicks += item.clicks
                tempCTR += item.ctr
                count += 1
              })
              if (tempCTR > 0.0) {
                this.totals.totalPeriod.ctr = tempCTR / count
              } else {
                this.totals.totalPeriod.ctr = 0.0
              }
            }
          })
        })
    },
  },
}
</script>
